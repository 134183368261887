<template>
  <div class='slide-list-container'>
    <b-row>
      <b-col cols="2" class="position-slider-wrap">
        <div class="position-slider-item"
             v-for="(item,index) in 5"
             @click="activeElement = index"
             :class="activeElement===index ? 'active' :'' "
             :key="index"
             :index-Element="getStatusImg.id"
        >広告枠#{{index + 1}}
        </div>
      </b-col>
      <b-col cols="5" class="slider-upload-wrap">
        <h4 class="slider-position-name">広告枠#{{activeElement+1}}</h4>
        <!--        preview img is upload succsess-->
        <div class="preview-img-wrap" v-show="imagePreview">
          <div class="preview-wrap-header d-flex justify-content-between">
            <p class="preview-img-title">アップロードした画像</p>
            <feather-icon class="preview-btn-close" @click="resetFormUpdate" icon="XIcon" size="18" />
          </div>
          <img v-show="imagePreview" class="img-preview" style="object-fit: cover;" ref="imgPreview" :src="imagePreview" alt="preview img">
        </div>
        <!--        form upload img-->
        <form v-show="!imagePreview" enctype="multipart/form-data" class="mt-1">
          <div class="dropbox">
            <input @change="previewImg" type="file" multiple accept="image/*" id="input-upload-file" class="input-file">
            <p class="drag-desc mt-3">
              アップロードしたいファイルを
            </p>
            <p class="drag-desc">ここにドラッグ＆ドロップ</p>
            <label class="btn-select-file mt-1" for="input-upload-file">またはファイルを選択</label><br>
          </div>
        </form>
        <!--        button displayed when an image is available  -->
        <b-col v-if="getStatusImg.status" cols="12" class="button-footer-wrap mt-1 d-flex justify-content-end">
          <b-button class="btn btn-cancer" @click="resetFormUpdate">キャンセル</b-button>
          <b-button v-if="getStatusImg.status == this.STATUS_IMG_ADS.public" class="btn btn-private" :disabled="isLoading" @click="updateImg(STATUS_IMG_ADS.private,getStatusImg.id)">非公開保存</b-button>
          <b-button v-if="getStatusImg.status == this.STATUS_IMG_ADS.private" class="btn btn-public" :disabled="isLoading" @click="updateImg(STATUS_IMG_ADS.public,getStatusImg.id)">公開</b-button>
        </b-col>
        <b-col v-else cols="12" class="button-footer-wrap mt-1 d-flex justify-content-end">
          <b-button class="btn btn-cancer" @click="resetFormUpdate">キャンセル</b-button>
          <b-button  class="btn btn-private" :disabled="isLoading" @click="upLoad(STATUS_IMG_ADS.private)">非公開保存</b-button>
          <b-button  class="btn btn-public" :disabled="isLoading" @click="upLoad(STATUS_IMG_ADS.public)">公開</b-button>
        </b-col>
      </b-col>
      <!--      demo slider phone-->
      <b-col cols="5" class="demo-slider">
        <img class="demo-slider-phone" :src="require('@/assets/images/pages/img.png')" alt="">
        <img v-show="imagePreview" style="object-fit: cover;" class="img-preview-demo-phone"
             :src="imagePreview" :alt="imagePreview" ref="imgPreview">
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BRow,BCol,BButton} from "bootstrap-vue";
import {apiService} from "@/services/api.service";
import {STATUS, STATUS_IMG_ADS} from "@/constant/constant";
import {nextTick} from "@vue/composition-api";
export default {
  name: 'SlideList',
  props: [],
  components:{
    BRow,
    BCol,
    BButton
  },
  data() {
    return {
      imagePreview:null,
      activeElement :0,
      fileUpload:{},
      listAdsGetApi:[],
      isLoading : null,
      getStatusImg : {
        id:null,
        image_path:null,
        status:null,
      },
      STATUS_IMG_ADS,
      isUploadImg : false,
      cloneInfoImg : null,
      statusLoadImg: false,
    }
  },
  methods: {
    async getListAds(){
      this.startLoading();
      try {
        let res = await apiService.get('ads');
        if (res && res.data && res.data.code === STATUS.SUCCESS){
          this.listAdsGetApi = res.data.data;
        }else this.notifyError(res.data.message);
      }catch (e){
        this.notifyError(e.data.message);
      }finally {
        this.endLoading();
      }
    },
    // hiển thị ảnh preview ( khi cha ấn upload mà mới chỉ chọn ảnh )
    previewImg(e) {
      this.fileUpload.file = e.target.files[0];
      if (this.fileUpload.file){
        this.imagePreview = URL.createObjectURL(this.fileUpload.file);
      }
    },
    // update status img
    async updateImg(statusImg,id){
      let newData = new FormData();
      newData.append("status",statusImg);
      if (this.fileUpload?.file){
        newData.append("image_path",this.fileUpload?.file);
      }
      this.startLoading();
      try {
        let res = await apiService.post(`ads/${id}`,newData);
        if (res && res.data && res.data.code === STATUS.SUCCESS ){
          this.notifySuccess(STATUS.MESSENGER_UPDATE_SUCCESS);
          this.getStatusImg = res.data.data;
          // upload status for the element after updating the state
          this.listAdsGetApi.forEach(item =>{
            if (item.id === this.getStatusImg.id){
              item.status = this.getStatusImg.status
            }
          });
          this.cloneInfoImg = {...this.getStatusImg};
          this.isUploadImg = false;
        }else this.notifyError(res.data.message);
      }catch (e){
        this.notifyError(e.data.message);
      }finally {
        this.endLoading();
      }
    },
    // upload img
    async upLoad(status){
      // if isUploadImg = true -> is in update state and image id exists -> call api update .
      // Otherwise, I will call the api to create a new image
      if (this.isUploadImg && this.cloneInfoImg.id){
        await this.updateImg(status,this.cloneInfoImg.id);
        // After uploading the image successfully, I will call back to the api to get the list of the most ms photos
        await this.getListAds();
      }else{
        let newData = new FormData();
        newData.append("image_path",this.fileUpload?.file);
        newData.append("status",status);
        this.startLoading();
        try {
          let res = await apiService.post(`ads`,newData);
          if (res && res.data && res.data.code === STATUS.SUCCESS ){
            // After uploading the image successfully, I will call back to the api to get the list of the most ms photos
            await this.getListAds();
            this.notifySuccess(STATUS.MESSENGER_CREATE_SUCCESS);
            this.getStatusImg = res.data.data;
            this.cloneInfoImg = {...this.getStatusImg};
          }else this.notifyError(res.data.message);
        }catch (e){
          this.notifyError(e.data.message);
        }finally {
          this.endLoading();
        }
      }
    },
    // get img detail
    async getImgPreiview(index){
      this.imagePreview = null;
      this.fileUpload.file = '';
      if (this.listAdsGetApi && this.listAdsGetApi[index]){
        this.getStatusImg = {...this.listAdsGetApi[index]}
        //create a clone object from the status img object to avoid making direct changes to the data
        this.cloneInfoImg = {...this.getStatusImg};
      }else{
        // if the image at the index position does not exist. image status will be an empty object
        // ->show form upload
        //because when the user clicks on the close button, all data of getStatusImg will have to be deleted
        // to update the form again. so have to create a clone to save the data
        this.getStatusImg = {}
      }
      if (this.getStatusImg.image_path != null){
        this.imagePreview = this.getStatusImg.image_path;
      }else {
        this.imagePreview = null
      }
    },
    // reset form update
    resetFormUpdate(){
      this.imagePreview = null;
      this.isLoading = true;
      this.getStatusImg = {};
      this.isUploadImg = true;
    },
  },
  async created() {
    await this.getListAds();
    await this.getImgPreiview(0);
    if (this.listAdsGetApi){
      this.cloneInfoImg = {...this.listAdsGetApi[0]};
    }
  },
  beforeUpdate() {
    this.isLoading = !this.imagePreview;
  },
  watch:{
    activeElement(){
      this.getImgPreiview(this.activeElement);
    }
  }
}
</script>

<style lang="scss" scoped>
.position-slider-wrap {
  .position-slider-item {
    font-weight: 700;
    cursor: pointer;
    width: 50%;
    margin: 20px auto 0 auto;
    text-align: center;
  }

  .active {
    background-color: #377DFF;
    color: #ffffff;
    border-radius: 10px;
    padding: 10px 10px;
  }
}
//form drag drop upload
.slider-upload-wrap{
  background-color: #ffffff;
  height: 100vh;
  padding: 40px;
  .slider-position-name{
    color: #000000;
    font-weight: 600;
  }
  // button fotter
  .button-footer-wrap{
    float: right;
  }
  .btn{
    margin-left: 10px;
  }
  .btn-cancer{
    background-color: #ffffff!important;
    color: #000000!important;
    border:1px solid #EDEDED !important;
    outline: none;
  }
  .btn-private{
    color: #377DFF !important;
    background-color: #ffffff !important;
    border: 1px solid #377DFF !important;
  }
  .btn-public{
    background-color: #377DFF !important;
    color: #ffffff !important;
    border: none;
  }
}
//form upload file
.dropbox {
  outline: 2px dashed #C7C7C7; /* the dash box */
  outline-offset: -10px;
  color: dimgray;
  min-height: 300px; /* minimum height */
  position: relative;
  cursor: pointer;
  padding: 50px 70px;
  border-radius: 20px;
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 98%;
    height: 95%;
    position: absolute;
    cursor: pointer;
    top: 10px;
    left: 8px;
  }
  .drag-desc{
    color: #000000;
    font-weight: 800;
    width: 220px;
    margin: 0 auto;
    line-height: 25px;
    text-align: center;
  }
  .btn-select-file{
    border: 1px solid #377DFF;
    border-radius: 5px;
    padding: 10px 15px;
    position: relative;
    cursor: pointer;
    color:#377DFF;
    font-weight: 600;
  }
  .btn-select-file:hover{
    opacity: 0.7;
  }
}
// demo silder
.demo-slider {
  height: 85vh;
  margin: 0 auto;
}
//responsive
//TABLET
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px){
  .demo-slider-phone {
    height: 70%;
    position: relative;
    left: 10%;
    width: 70%;
  }
  .demo-slider{
    .img-preview-demo-phone{
      height: 8%;
      position: absolute;
      left: 20%;
      width: 52.5%;
      top: 13%;
    }
  }
  .btn-select-file{
    left: 18%;
  }
}
//laptop > 16
@media only screen
and (min-width: 1370px)
and (max-width: 1600px) {
  .demo-slider-phone {
    height: 100%;
    position: relative;
    left: 10%;
    width: 70%;
  }
  .demo-slider{
    .img-preview-demo-phone{
      height: 11%;
      position: absolute;
      left: 19.5%;
      width: 53%;
      top: 18%;
    }
  }
  .btn-select-file{
    left: 27%;
  }
}
//laptop <= 14
@media only screen
and (min-width: 1030px)
and (max-width: 1366px){
  .demo-slider-phone {
    height: 100%;
    position: relative;
    left: 10%;
    width: 80%;
  }
  .demo-slider{
    .img-preview-demo-phone{
      height: 11%;
      position: absolute;
      left: 21%;
      width: 59.5%;
      top: 18%;
    }
  }
  .btn-select-file{
    left: 27%;
  }
}
//pc
@media only screen and (min-width: 1600px){
  .demo-slider-phone {
    height: 100%;
    position: relative;
    left: 10%;
    width: 66%;
  }
  .demo-slider{
    .img-preview-demo-phone{
      height: 10.5%;
      position: absolute;
      left: 19%;
      width: 50.1%;
      top: 18.5%;
    }
  }
  .btn-select-file{
    left: 35%;
  }
}
// preivew img is upload succses
.preview-img-wrap{
  background-color: #ffffff;
  width:100%;
  height: 200px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  margin-top: 20px;
  .img-preview{
    width: 100%;
    height: 80%;
    margin: 0 auto;
  }
  .preview-btn-close{
    cursor: pointer;
    background-color: #5C5C5C;
    color: #ffffff;
    padding: 2px;
    border-radius: 15px;
  }
}
</style>
